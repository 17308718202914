// components
@import "./components/variables.scss";
@import "animate.css/animate.compat.css";
@import "~slick-carousel/slick/slick.css"; 
@import "~slick-carousel/slick/slick-theme.css";
@import "./components/base.scss";
@import "./components/button.scss";
@import "./components/breadcrumb.scss";
@import "./components/icon.scss";
@import "./components/preloader.scss";
@import "./components/sliderRotator.scss";
@import "./components/header.scss";
@import "./components/footer.scss";
@import "./components/page.scss";
@import "./components/collectionGrid.scss";
@import "./components/collection.scss";
@import "./components/accordeon.scss";
@import "./components/cartItems.scss";
@import "./components/cart.scss";
@import "./components/minicart.scss";
@import "./components/columns.scss";
@import "./components/variantSelector.scss";

@import "./components/checkout.00125a9524dffa2a513b.scss";


html, body {
  padding: 0px;
  margin: 0px;
  font-family: "Assistant", sans-serif;
}

.slow_ani {
  -webkit-transition: all .25s cubic-bezier(.77,0,.175,1);
  -moz-transition: all .25s cubic-bezier(.77,0,.175,1);
  -o-transition: all .25s cubic-bezier(.77,0,.175,1);
  transition: all .25s cubic-bezier(.77,0,.175,1);
}

.re,
.pl-rely {
  position: relative;
  display: table;
  height: 100%;
  width: 100%;
  z-index: 2;

  .ce,
  .pl-centry {
    position: relative;
    vertical-align: middle;
    display: table-cell;
    z-index: 1;
  }
}