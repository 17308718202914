.breadcrumb-container {
  position: relative;
  padding: 10px 0px;
  border-bottom: 1px solid rgb(236, 236, 236);
  margin-bottom: 16px;

  a {
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
    color: #000;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
  span {
    display: inline-block;
    vertical-align: middle;
    opacity: 0.6;
  }
  &--separator {
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
    opacity: 0.5;
  }
}