.pl-global-button {
  position: relative;
  width: 100%;
  background-color: gray;
  border: 0px;
  height: 34px;
  text-align: center;
  cursor: pointer;
  color: #fff;

  img {
    width: 20px;
    height: auto;
  }
}