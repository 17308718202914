.slider-rotator {
    background-color: rgb(240, 240, 240);
    margin-bottom: 30px;
    .slider-rotator__slide {
        box-sizing: border-box;
        outline: 0px !important;

        &.slide-type-image {
            img {
                width: 100%;
                height: auto;
                outline: 0px !important;
                margin-bottom: -4px;
            }
        }
    }
}