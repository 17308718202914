.pl-cart-item {
  height: 135px;
  position: relative;
  margin: 10px 0px;
  margin-bottom: 16px;
  border-bottom: 1px solid rgb(238, 238, 238);

  &:last-child {
    border-bottom: 0px;
  }

  &--image {
    position: absolute;
    left: 4px;
    top: 0px;
    bottom: 8px;
    width: 110px;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    display: block;
  }
  &--labels {
    position: absolute;
    left: 130px;
    right: 36px;
    top: 0px;
    bottom: 8px;

    &--name {
      font-size: 14px;
      line-height: 16px;
      font-weight: 600;
      margin-bottom: 4px;
    }
    &--desc {
      font-size: 14px;
      line-height: 16px;
    }
    &--price {
      font-size: 16px;
      line-height: 18px;
      margin: 10px 0px;
    }
  }
  &--actions {
    position: absolute;
    left: 130px;
    right: 16px;
    height: 32px;
    bottom: 10px;

    &--qty {
      height: 100%;
      width: 110px;
      position: relative;
      display: inline-block;

      input[type="number"]::-webkit-inner-spin-button,
      input[type="number"]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        appearance: none;
        margin: 0;
      }
      input[type="number"] {
        -moz-appearance: textfield;
        padding: 10px;
        text-align: center;
      }

      input {
        padding: 0;
        position: absolute;
        left: 36px;
        right: 36px;
        width: auto;
        bottom: 0px;
        top: 0px;
        border: 0;
      }
      button {
        position: absolute;
        top: 0;
        bottom: 0;
        border: 0;
        width: 32px;
        background: #fcfcfc;
        font-weight: bold;
        cursor: pointer;
        padding: 0px;
        border-radius: 6px;
        box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.05);
        -webkit-box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.05);
        -moz-box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.05);
        z-index: 2;

        .pl-icon {
          display: block;
          margin: 0;
          width: 100%;
          height: 100%;
          transform: scale(0.5);
          span {
            svg {

            }
          }
        }
        
        
        &:hover {
          box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.16);
          -webkit-box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.16);
          -moz-box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.16);
        }

        &:first-child {
          left: 0px;
        }
        &:last-child {
          right: 0px;
        }
      }

    }
  }

  &--overlay {
    position: absolute;
    left: 0px;
    top: 0px;
    bottom: 0px;
    right: 0px;
    z-index: 2;
    background-color: rgba($color: #ffffff, $alpha: 0.7);
    text-align: center;
    cursor: no-drop;

    .pl-preloader-container__section {
      transform: scale(1.3);
    }
  }

  &--delete {
    position: absolute;
    top: 0px;
    right: 10px;
    cursor: pointer;
    opacity: 0.7;

    &:hover {
      opacity: 0.5;
    }

    .pl-icon {
      width: 20px;
      height: 20px;
    }
  }

  &--total {
    display: none;
  }
}