.cart-items {
  .cart__contents {
    .cart__header {
      position: relative;
      height: 34px;
      border-bottom: 1px solid rgb(226, 226, 226);
      margin-bottom: 20px;
      margin-top: 20px;

      div {
        position: absolute;
        top: 0px;
        font-weight: bold;
        &:nth-child(1) {
          left: 0px;
        }
        &:nth-child(2) {
          display: none;
          @media only screen and (min-width: 992px) {
            display: block;
            right: 200px;
          }
        }
        &:nth-child(3) {
          right: 0px;
        }
      }
    }
    .cart__items {
      .cart-items {
        width: 100%;

        .pl-cart-item {

          &--total {
            display: block;
            position: absolute;
            right: 0px;
          }
          &--delete {
            right: 0px;
            bottom: 17px;
            top: auto;
          }

          @media only screen and (min-width: 992px) {
            &--actions {
              left: auto;
              right: 160px;
              top: 0;
              bottom: 0;
              margin: auto;
            }
            &--delete {
              margin: auto;
              bottom: 0;
              top: -4px;
              width: 20px;
              height: 20px;
              right: 105px;
            }
            &--total {
              top: 40%;
            }
          }
        }
        
      


      }
    }
  }
}