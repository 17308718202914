.section-header {
  position: sticky;
  height: 80px;
  outline: 1px solid red;
  background-color: #fff;
  z-index: 4;
  &.active{
    top: var(--_top);
  }

  .page-width  {
    height: 100%;
  }
  header {
    height: 100%;

    .section-header-logo {
      position: absolute;
      right: 124px;
      top: 0px;
      left: 12px;
      height: 100%;

      .company-name {
        font-size: 25px;

        a {
          color: #000;
          text-decoration: none;
        }
      }
    }

    .section-header-icons {
      position: absolute;
      width: 100px;
      top: 0px;
      height: 100%;
      right: 12px;
      text-align: right;

      .pl-minicart-container {
        width: 28px;
        display: inline-block;
        vertical-align: top;
      }
    }
  }
}