.cLGHj {
  position: relative;
  width: 100%;
  background-color: #fff;
  min-height: 600px;

  @media only screen and (min-width: 992px) {
    width: 52%;

    ._1fragemwgone {
      width: 100%;
      float: right;
    }
  }

  @media only screen and (min-width: 1180px) {
    ._1fragemwgone {
      width: 620px;
    }
  }
}

._1ip0g651 {
  @media only screen and (min-width: 1180px) {
    .g6jbm7 {
      display: none;
    }
  }
}

.g6jbm7 {
  .g6jbm8 {
    display: none;
  }
}
._1ip0g651 {
  .blA7b {
    ._1ip0g651 {
      display: none;
    }
  }
}

.jCic4 {
  position: fixed;
  right: 0px;
  top: 0px;
  bottom: 0px;
  width: 48%;
  background-color: whitesmoke;
  border-left: 1px solid rgb(216, 216, 216);
  z-index: 2;
  display: none;

  @media only screen and (min-width: 992px) {
    display: block;
  }
}

._1fragemo1 {

    .cektnc3 {
        display: none !important;
    }

    ._7ozb2uh {
        grid-template-areas:
            "iconStart prefix field suffix iconEnd accessory"
            ". . field . . .";
        grid-template-columns: auto auto 1fr auto auto auto;
        grid-template-rows: 50px 1fr;
        background-color: #fff;
        color: #000;
        input {
            outline: 0 solid #0000;
            width: 100%;
            inline-size: 100%;
            position: relative;
            overflow: visible;
            font-size:  14px;
            height: 50px;
            padding: 13px 11px;
            box-sizing: border-box;
            border-radius: 5px;
            border: 1px solid rgb(204, 204, 204);
            -webkit-transition: all .3s ease;
            -moz-transition: all .3s ease;
            -o-transition: all .3s ease;
            transition: all .3s ease;

            &:focus {
                outline: none !important;
                border: 1px solid #1773b0;
                box-shadow: 0 0 0 1px #1773b0;
            }
        }
    }

    ._1mmswk9g {
      display: inline-block;
      position: relative;
      margin: 18px 0px;
      padding-left: 28px;
      cursor: pointer;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;

      &.last {
        margin-top: 4px;
        margin-bottom: 20px;
      }

      input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
      }

      input:checked ~ ._1mmswk9g__checkmark {
        border: 1px solid #1773b0;

        &:before {
          transform: scale(0);
        }
      }
      input:checked ~ ._1mmswk9g__checkmark:after {
        opacity: 1;
      }
      &:hover input ~ ._1mmswk9g__checkmark {
        background-color: #1773b0;
      }
      &:hover input:checked ~ ._1mmswk9g__checkmark {
        
      }

      ._1mmswk9g__checkmark {
        position: absolute;
        top: 0px;
        left: 0;
        height: 18px;
        width: 18px;
        background-color: #1773b0;
        transition: background-color 0.25s ease;
        border-radius: 4px;
        border: 1px solid rgb(216, 216, 216);
        -webkit-transition: all .3s ease;
        -moz-transition: all .3s ease;
        -o-transition: all .3s ease;
        transition: all .3s ease;

        &:before {
          content: "";
          position: absolute;
          left: 0px;
          top: 0px;
          bottom: 0px;
          right: 0px;
          z-index: 1;
          background-color: #fff;
          -webkit-transition: all .25s cubic-bezier(.77,0,.175,1);
          -moz-transition: all .25s cubic-bezier(.77,0,.175,1);
          -o-transition: all .25s cubic-bezier(.77,0,.175,1);
          transition: all .25s cubic-bezier(.77,0,.175,1);
        }

        &:after {
          content: "";
          position: absolute;
          left: 6px;
          top: 2px;
          width: 5px;
          height: 10px;
          border: solid #fff;
          border-width: 0 2px 2px 0;
          transform: rotate(45deg) scale(0.8);
          opacity: 0;
          transition: opacity 0.25s ease;
        }
      }
      ._1mmswk9g__body {
        color: #000;
        line-height: 1.4;
        font-size: 15px;
      }
    }

    ._7ozb2u2 {
      margin: 12px 0px;
    }

    section {
      padding-bottom: 18px;
      .g3ArD {
        .n8k95w1 {
          font-size: 22px;
          margin-top: 0px;
          margin-bottom: 10px;
          font-weight: 600;

          &._tlevel {
            font-size: 16px;
          }
        }
        p {
          font-size: 14px;
          margin-top: -6px;
        }
      }
    }
}

.blA7b {
  box-sizing: border-box;

  @media only screen and (min-width: 992px) {
    padding: 38px;
    max-width: 520px;
  }

  table {
    border-collapse: collapse;
    width: 100%;

    tr {
      td {
        padding: 4px 0px;
        font-weight: 400;
        color: #000;
        font-size: 14px;
        &:nth-child(2) {
          text-align: right;
        }
      }

      &:last-child {
        td {
          font-weight: 600;
          font-size: 19px;
          padding-top: 6px;

          span {
            font-size: 12px;
            font-weight: 400;
            margin-right: 8px;
            display: inline-block;
            vertical-align: middle;
          }

          &:last-child {
            font-size: 21px;
          }
        }
      }
    }
  }
}

.U6oc4 {
  margin-bottom: 20px;
  ._1fragempsC {
    width: 100%;

    ._1ip0g651 {
      margin-bottom: 16px;
    }

    @media only screen and (max-width: 670px) {
      padding: 0px 14px;
    }
  }

  .U6oc8 {
    ._1fragempsC {
      @media only screen and (max-width: 670px) {
        padding: 0px;
      }
    }
  }

  @media only screen and (min-width: 992px) {
    display: none;
  }
}

._1ip0g652 {
  position: relative;
  margin-bottom: 60px;
  margin-top: 4px;

  button {
    background-color: #1773b0;
    display: block;
    width: 100%;
    border: 0px;
    border-radius: 4px;
    color: #fff;
    height: 58px;
    font-size: 18px;
    cursor: pointer;
    -webkit-transition: all .4s ease;
    -moz-transition: all .4s ease;
    -o-transition: all .4s ease;
    transition: all .4s ease;

    &:hover {
      background-color: #105989;
    }
  }
}

.mZR1U  {
  .TfwLd {
    border-top: 1px solid rgb(228, 228, 228);

    button {
      color: #1773b0;
      background-color: transparent;
      border: 0px;
      height: 54px;
      text-decoration: underline;
      font-size: 12px;
    }
  }
}

.g9gqqf1 {
  .pl-cart-item {
    height: 62px;
    position: relative;
    margin: 10px 0px;
    &--delete,
    &--actions {
      display: none;
    }
    &--image {
      left: 0px;
      height: 62px;
      width: 62px;
      border: 1px solid rgb(200, 200, 200);
      border-radius: 4px;

      span {
        position: absolute;
        width: 20px;
        height: 20px;
        z-index: 1;
        right: -8px;
        top: -10px;
        background-color: #666;
        border-radius: 100px;
        color: #fff;
        text-align: center;
        font-size: 14px;
        line-height: 19px;
      }
    }
    &--labels {
      left: 78px;
      bottom: 0px;
      &--price {
        display: none;
      }
      &--name {
        height: 100%;
        span {
          color: #000;
          font-size: 14px;
          font-weight: 400;
          display: block;
          margin: 3px 0px;

          &:nth-child(2) {
            font-size: 12px;
            opacity: 0.8;
          }
        }
      }
    }
    &--total {
      display: block;
      position: absolute;
      right: 0px;
      top: 20px;
      color: #000;
    }
  }
}