.pl-variant-selector {
  position: relative;

  &__option {
    position: relative;
    height: 50px;
    margin-bottom: 4px;

    &--head {
      font-size: 12px;
      display: block;
      opacity: 0.7;
    }
    &--items {
      display: block;
      width: 100%;

      &__item {
        display: inline-block;
        vertical-align: top;
        width: 22px;
        height: 22px;
        cursor: pointer;
        outline: 1px solid rgb(195, 195, 195);
        margin: 5px;

        &.active {
          outline: 1px solid green;
        }
        &.disable {
          opacity: 0.5;
          cursor: no-drop;
        }
      }
    }
  }
}