.image-text-section {
  margin: 32px 0px;
}

.pl-columns {
  position: relative;

  &__item {
    position: relative;

    h2 {
      font-family: "Assistant", sans-serif;
      font-size: 30px;
      line-height: 34px;
      font-weight: 400;
      margin: 0px;

      @media only screen and (min-width: 992px) {
        padding-right: 60px;
      }
    }
    p {
      font-size: 16px;
      font-weight: 400;

      @media only screen and (min-width: 992px) {
        padding-right: 60px;
      }
    }

    img {
      width: 100%;
      height: auto;
    }
  }

  &__col-mobile-1 {
    height: auto !important;

    .pl-columns__item {
      display: block;
      width: 100%;
      margin-left: 0px;
      margin-right: 0px;
    }
  }

  &__col-mobile-2,
  &__col-mobile-3,
  &__col-mobile-4 {
    display: flex;

    .pl-columns__item {
      flex: 1;
    }
  }

  &__col-desk-2,
  &__col-desk-3,
  &__col-desk-4 {
    @media only screen and (min-width: 992px) {
      display: flex !important;

      .pl-columns__item {
        flex: 1;
      }
    }
  }
}