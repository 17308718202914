.section-footer {
  position: sticky;
  height: 90px;
  background-color: #fff;
  z-index: 2;
  border-top: 1px solid rgb(240, 240, 240);
  margin-top: 0px;

  @media only screen and (min-width: 992px) {
    margin-top: 70px;
  }

  .page-width  {
    height: 100%;
  }
  footer {
    height: 100%;
    font-size: 12px;
    color: #121212bf;

    a {
      color: #121212bf;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
        color: #000;
      }
    }
  }
}