.collection {
  padding:  30px 0px;
  .collection__title {
    h2 {
      font-family: $fontFamily;
      font-size: 30px;
      line-height: 34px;
      font-weight: 400;
      margin: 0px;
    }
  }
  .rich-text__text {
    margin-top: 16px;
    p {
      margin: 0px;
      font-size: 16px;
      font-weight: 400;
      margin-bottom: 0px;
    }
  }

  .product-grid {
    margin: 0;
    padding: 0;
    column-gap: 5px;
    row-gap: 5px;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 2rem;
    padding-top: 30px;
    list-style: none;

    @media only screen and (min-width: 992px) {
      column-gap: 20px;
      row-gap: 20px;
    }

    .grid__item {
      list-style: none;
      width: calc(50% - 5px * 3 / 4);

      @media only screen and (min-width: 992px) {
        width: calc(25% - 20px * 3 / 4);
      }

      .product-card-wrapper {
        .card {
          .card__inner {
            transition: transform 0.5s ease, box-shadow 0.5s ease;
            a {
              display: block;

              img {
                width: 100%;
                height: auto;
                margin-bottom: -5px;
              }
            }
          }
          .card__content {
            height: 190px;
            .card__information {
              .card__heading {
                margin: 8px 0px;
                a {
                  text-decoration: none;
                  color: currentColor;
                  display: block;
                  color: $colorText;
                  font-size: 0.9rem;
                  font-weight: 400;

                  &::after {
                    bottom: 0;
                    content: "";
                    left: 0;
                    position: absolute;
                    right: 0;
                    top: 0;
                    z-index: 1;
                  }
                }
              }
            }
            .card__actions {
              position: relative;
              z-index: 3;
            }
          }
        }

        &:hover {
          .card {
            .card__inner {
              transform: scale(1.02);
              box-shadow: -0.5rem -0.5rem 0.5rem -0.5rem #0000000d, 0.5rem 0.5rem 0.5rem -0.5rem #0000000d, 0 0 .5rem #fff0, 0 1.8rem 2.5rem -2rem #00000080;
            }
          }
        }
      }
    }
  }

  .collection__view-all {
    text-align: center;

    a {
      display: inline-block;
      background-color: #000;
      color: #fff;
      text-decoration: none;
      padding: 6px 18px;
      font-size: 14px;
      cursor: pointer;

      &:hover {
        opacity: 0.8;
      }
    }
  }
}