.U6oc6 {
	width: 100%;
	overflow: hidden;
	background-color: #f5f5f5;

  .U6oc7 {
		position: relative;
		cursor: pointer;
    border: 0px;
    width: 100%;
    text-align: left;
    height: 62px;
    border-top: 1px solid #e4e4e4;
    border-bottom: 1px solid #e4e4e4;
    color: #105989;
    font-size: 13px;
  }

  .U6oc8 {
		padding: 0 20px;
		max-height: 0;
		overflow: hidden;
		will-change: max-height;
		transition: all 0.25s ease-out;
		color: #f1f1f1;
		opacity: 0;
  }

  &.active {
    border-bottom: 1px solid #e4e4e4;
    
    .U6oc8 {
      padding: 0 20px 20px;
			opacity: 1;
    }
  }
}